import * as React from "react"
import {useStaticQuery, graphql, Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"

const BlogCard = () => {
    const data = useStaticQuery(
        graphql`
        query {
          allMicrocmsBlog(sort: { fields: [createdAt], order: DESC }) {
            edges {
              node {
                blogId
                title
                publishedAt(formatString: "YYYY/MM/DD")
                eyecatch {
                  url
                }             
              }
            }
          }
  } 
        `
    )    
    return (        
        <div className="blog-area ptb-100">
            <div className="container">



                <div className="row">

                {data.allMicrocmsBlog.edges.map(({ node }) => (
					<div className="col-lg-4 col-md-6" key={node.blogId}>
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to={`/blog/${node.blogId}`}>


{node.eyecatch ? (
            <img
              src={node.eyecatch.url}
              as={`figure`}
              style={{ aspectRatio: "25/14" }}
              alt={node.title}
            />
          ) : (
            <StaticImage
              src={`../../assets/images/blog/blog-img4.jpg`}
              alt=""
              as={`figure`}
              style={{ aspectRatio: "25/14" }}
            />
          )}
  
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                        
                                        Bgreen Staff
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i>{node.publishedAt}
                                    </li>
                                </ul>
                                <h3>
                                    <Link to={`/blog/${node.blogId}`}>
                                        {node.title}
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
					))}
                    
                </div>
            </div>
        </div>
    )
}

export default BlogCard
